$(document).ready(function () {
  var $agencyTypeListTemplate = $('#agencyTypeTemplate');
  var $agencyTypeList = $('.js-agency-type-list');

  function agencyTypeData() {
    window.APICaller.getAgencyTypeDataService().then(function(data) {

      populateAgencyTypeData(data ? data.agencyTypes : []);
    });
  }

  function populateAgencyTypeData(data) {
    var agencyTypeList = Handlebars.compile($agencyTypeListTemplate.html());
    var htmlAgencyTypeCompiled = agencyTypeList({ data: data });
    $agencyTypeList.html(htmlAgencyTypeCompiled);
  }

  agencyTypeData();
});
